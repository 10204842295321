/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Tooltip } from '@mui/material';

import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/system';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import { logPostHogClick, getProductLink } from '@utils/index';
import { addAffiliateTagToLink } from '@utils/formatUtils';
import { trimPromotion } from '@components/PromotionChip/utils';

interface CouponChipProps extends DealPostType {
  tag?: string;
}

const PromotionChip = (props: CouponChipProps) => {
  const { ASIN, tag, extraDeal, extraDealLink } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const affiliateTag = tag || config.AFFILIATE_TAGS.DEAL;

  const handleCouponChipClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const href = extraDealLink
      ? addAffiliateTagToLink(extraDealLink, affiliateTag)
      : getProductLink(ASIN, tag);
    const item = 'promotion-chip';
    const toLog = {
      ASIN,
      extraDeal
    };
    const clickType = 'promotion-chip-click';

    // open href in new window
    window?.open(href, '_blank', 'noopener,noreferrer');

    trackUse({
      item: item || clickType || tag,
      value: href,
      type: 'CLICK'
    });

    logPostHogClick(href, clickType, tag, item, toLog);
  };

  const promotionTooltip = `Also eligible for promotion: ${extraDeal}`;
  const color = isDarkMode ? '#c488f7' : '#6900c2';

  const chipStyles = {
    marginRight: '4px !important',
    fontSize: '11px !important',
    borderColor: color,
    marginTop: '0.35em !important'
  };

  return (
    <span key="p-chip">
      <Tooltip enterDelay={1000} placement="top" title={promotionTooltip}>
        <Chip
          id={`promotion_chip_${ASIN}`}
          sx={chipStyles}
          size="small"
          onClick={handleCouponChipClick}
          label={trimPromotion(extraDeal)}
          variant="outlined"
        />
      </Tooltip>
    </span>
  );
};

export default PromotionChip;
