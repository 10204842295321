export function trimPromotion(promotion: string): string {
  // If 40 or fewer characters, return as is.
  if (promotion.length <= 40) return promotion;

  for (let i = 0; i < promotion.length; i++) {
    if (promotion[i] === '.') {
      // Check if the period is part of a number.
      const isPrevDigit = i > 0 && /\d/.test(promotion[i - 1]);
      const isNextDigit =
        i < promotion.length - 1 && /\d/.test(promotion[i + 1]);
      if (isPrevDigit && isNextDigit) {
        // This period is between two numbers (like a price), so skip it.
        // eslint-disable-next-line no-continue
        continue;
      } else {
        // Use the text up to this period.
        return promotion.substring(0, i).trim();
      }
    }
  }

  // If no period outside a numeric context was found, slice at 37 characters and append ellipsis.
  return `${promotion.slice(0, 37)}...`;
}
